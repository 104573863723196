<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Nieuw item" />

    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Verstuur bericht</h3>
            <p class="mt-1 text-sm text-gray-600">
              Na het klikken op verstuur zal er een email met notificatie naar de ontvanger(s) worden verstuurd. Bij het inloggen van de gebruiker zal er ook een indicatie worden getoond dat er een nieuw ongelezen bericht klaar staat.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          
          <form @submit.prevent="handleSave" method="POST">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-6">
                    <label for="title" class="block text-sm font-medium text-gray-700">Onderwerp</label>
                    <input type="text" name="title" id="title" autocomplete="title" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" required v-model="message.subject">
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="users" class="block text-sm font-medium text-gray-700">Users</label>
                    <Multiselect
                      v-model="selectedUsers"
                      mode="tags"
                      :options="allUsers"
                      :searchable="true"
                      :classes="{
                        container: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white text-base leading-snug outline-none',
                        containerDisabled: 'cursor-default bg-gray-100',
                        containerOpen: 'rounded-b-none',
                        containerOpenTop: 'rounded-t-none',
                        containerActive: 'ring ring-blue-500 ring-opacity-30',
                        singleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
                        multipleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
                        search: 'w-full absolute inset-0 outline-none appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5',
                        tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2',
                        tag: 'bg-blue-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap',
                        tagDisabled: 'pr-2 !bg-gray-400 text-white',
                        tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
                        tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
                        tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
                        tagsSearch: 'absolute inset-0 border-0 outline-none appearance-none p-0 text-base font-sans box-border w-full',
                        tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
                        placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400',
                        caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
                        caretOpen: 'rotate-180 pointer-events-auto',
                        clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
                        clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
                        spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0',
                        dropdown: 'absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
                        dropdownTop: '-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t',
                        dropdownHidden: 'hidden',
                        options: 'flex flex-col p-0 m-0 list-none',
                        optionsTop: 'flex-col-reverse',
                        option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
                        optionPointed: 'text-gray-800 bg-gray-100',
                        optionSelected: 'text-white bg-blue-500',
                        optionDisabled: 'text-gray-300 cursor-not-allowed',
                        optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
                        optionSelectedDisabled: 'text-blue-100 bg-blue-500 bg-opacity-50 cursor-not-allowed',
                        noOptions: 'py-2 px-3 text-gray-600 bg-white',
                        noResults: 'py-2 px-3 text-gray-600 bg-white',
                        fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
                        spacer: 'h-9 py-px box-content',
                      }"
                    />
                  </div>

                  <div class="col-span-6 sm:col-span-6">
                    <label for="intro" class="block text-sm font-medium text-gray-700">Description</label>
                    <TinyMceEditor v-model="message.body" model_name="Message" :image="false" />
                  </div>
                  
                </div>
              </div>

              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" @click="saveItem">
                  Verstuur
                </button>
              </div>
            </div>
          </form>
          
        </div>
      </div>
    </div>

  </main>
</template>

<script>
import MessageService from '../../../services/admin/messages/service';
import TinyMceEditor from '../../../components/admin/generic/TinyMceEditor.vue';
import Multiselect from '@vueform/multiselect'
import { ref, onMounted } from "vue";

export default {
  name: 'NewMessage',
  components: {
    TinyMceEditor,
    Multiselect
  },
  setup() {
    const message = ref({
      subject: '',
      body: '',
      userIds: []
    });
    const saving = ref(true);
    const selectedUsers = ref([]);
    const allUsers = ref(null);

    saving.value = false;
    allUsers.value = [];

    const fetchAllUsers = async function() {
      await MessageService.getAllUsers().then(({data})=>{
        allUsers.value = data.map((t)=> {
          return {
            value: t.id,
            label: `${t.first_name} ${t.last_name}`
          }
        })
      });
    }

    onMounted(async () => {
      await fetchAllUsers();
    });

    return {
      message,
      allUsers,
      selectedUsers,
      saving
    };
    
  },
  methods: {
    async handleSave() {
      this.saving = true;
      await MessageService.saveMessage(this.message, this.selectedUsers);
      this.emitter.emit('notification', {type: 'success', message: 'Verstuurd!', description: 'Bericht is nu naar de ontvanger(s) verstuurd. Er komt nog een interface waar je je verstuurde berichten kan bekijken', duration: 50000});
      this.saving = false;
    }
  }
}
</script>
